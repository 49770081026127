/* eslint no-console:0 */

import { Application } from "stimulus"
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
import 'alpinejs'

import { definitionsFromContext } from "stimulus/webpack-helpers"
require("stylesheets/application.scss")
require("fonts/lacuna-italic-webfont.woff")
require("fonts/lacuna-webfont.woff")

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

function showVideo(videoId){
    document.querySelector('.video-player-overlay').style.display = 'flex';
    const iframeHolder = document.querySelector('.video-iframe');
    iframeHolder.innerHTML = `<iframe id="ytplayer" type="text/html" width="100%" height="100%" src="https://www.youtube.com/embed/${videoId}?autoplay=1"
    frameborder="0" allowfullscreen=""></iframe>`;
}

function hideVideo() {
    const overlay = document.querySelector('.video-player-overlay');
    if(overlay){
        overlay.style.display = 'none';
        const iframeHolder = document.querySelector('.video-iframe');
        iframeHolder.innerHTML = '';
    }
}

function setupVideos(){
    if(document.querySelector('.video-player-overlay')!=null){
        document.addEventListener('keydown',function(e){
            if(e.key == "Escape"){
                hideVideo();
            }
        });
        document.querySelector('.video-close').addEventListener('click', function(){
            hideVideo();
        });

        document.querySelector('.video-player-overlay').addEventListener('click', function(e){
            if(e.target.classList.contains('video-player-overlay')){
                hideVideo();
            }
        });

        document.querySelectorAll('.video-container').forEach((x) => {
            x.addEventListener('click', (e) => {
                const videoId = x.getAttribute('data-video-id');
                showVideo(videoId);
            });
        });
    }
}

document.addEventListener('turbolinks:load', () => {
    setupVideos();
    document.querySelectorAll('.project-tile').forEach((x) => {
        x.addEventListener('click', (e) => {
            document.querySelector('.project-main').src = x.src;
        });
    });


    const wideOverflow = document.querySelector('.overflower.wide');
    console.log(wideOverflow);
    if(wideOverflow){
        wideOverflow.addEventListener('mousewheel', function(e){
            if(e.deltaX!=0){
                wideOverflow.scrollLeft += e.deltaX;
            } else {
                wideOverflow.scrollLeft += e.deltaY;
            }
            e.preventDefault();
            e.stopPropagation();
            return false;
        });
    }
});
